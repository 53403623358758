import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"How do we choose our words—phrases—sentences? And why does it matter?","key":"how-do-we-choose-our-words-phrases-sentences-and-why-does-it-matter","parentKey":"writing-involves-choices"};

  